import React, {Component} from 'react';
import Slider from "react-slick";
import {NextArrow, PrevArrow} from "../ArrowSlider";
import {imageStorage} from "../../classes/Helper";

export default class extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 0,
            arrows: false,
            nextArrow: <NextArrow type='turquoise'/>,
            prevArrow: <PrevArrow type='turquoise'/>,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: true,
                        infinite: true,
                    }
                },
            ]
        },
            { rates } = this.props;

        return (
            <div className="rates-container main-container" id="rates">
                <div className="heading">
                    Абонементы
                </div>

                <div className="rates">
                    <Slider {...settings}>
                        {
                            rates.map((val,index) => {
                                return <div className={"rate-block " + (index === 0 ? 'first' : index === 1 ? 'second' : 'third')} key={index}>
                                    <div className="bg-image" style={{
                                        backgroundImage: `url(${imageStorage + '/rates/' + val.image})`
                                    }}/>
                                    <div className="duration-rate">
                                        {val.count}
                                    </div>
                                    <div className="content-rate">
                                        <div className="name-rate">
                                            {val.name}
                                        </div>
                                        <div className="price-rate">
                                            {val.price}
                                        </div>
                                    </div>
                                    <div className="rate-ellipse"/>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
        );
    }
}
