import React, {Component} from 'react';
import Slider from "react-slick";
import {NextArrow, PrevArrow} from "../ArrowSlider";
import Picture from "../../components/Picture";
import NewsItem from "../../components/news/newsItem";
import images  from "../../assets/images";
import Helper, {imageStorage} from "../../classes/Helper";
import Parser from 'html-react-parser';

const { cross, more_button_triangle } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            visibleContent: false,
            newsContent: '',
            typeContent: '',
        };
    }

    componentDidMount() {

    }

    visibleContent = (visible,element, content) => {
        let differentIndex = this.state.typeContent;

        if(element){
            const currentSLickIndex = element.target.closest('.slick-track').querySelector('.slick-current').getAttribute('data-index'),
                clickedSLickIndex = element.target.closest('.slick-active').getAttribute('data-index');
                differentIndex = clickedSLickIndex - currentSLickIndex;
        }

        this.setState({
            visibleContent: visible,
            newsContent: content ? content : this.state.newsContent,
            typeContent: differentIndex === 0 ? 'left' : differentIndex === 1 && Helper.isDesktop() ? 'center' : differentIndex === 2 ? 'right' : 'right',
        })
    }

    _onClose = () => {
        return this.visibleContent(false);
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
            beforeChange: this._onClose,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        dots: false,
                    }
                },
            ]
        },
            { visibleContent, newsContent, typeContent } = this.state,
            { news } = this.props;

        return (
            <div className="news-container" id="news">
                <div className="main-container">
                    <div className="heading">
                        Новости
                    </div>
                    <div className="description">
                        Интересно?
                    </div>

                    <div className="news-list">
                        <Slider {...settings}>
                            {
                                news.map((val,index) => {
                                    return <NewsItem
                                        key={index}
                                        image={imageStorage + '/news/' + val.image}
                                        date={val.created_at}
                                        title={val.name}
                                        desc={val.text}
                                        visibleContent={this.visibleContent}
                                        visibleContentActive={visibleContent}
                                    />
                                })
                            }
                        </Slider>
                    </div>

                    <div className={"news-content-container " + typeContent + (visibleContent ? ' opened' : '')}>
                        <div className="news-content">
                            <div className="close-window" onClick={this._onClose}>
                                <Picture icon={cross} height={'21px'} alt={'cross'}/>
                            </div>
                            {Parser(newsContent)}

                            <button className="button-close-not-desktop" onClick={this._onClose}>
                                <Picture icon={more_button_triangle} height={7} alt={'more button triangle'}/>
                                <span>свернуть</span>
                            </button>
                        </div>
                    </div>

                    <div className="news-ellipse"/>
                    <div className="news-double-ellipse"/>
                </div>
            </div>
        );
    }
}
