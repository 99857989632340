import React, { Component } from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";
import {imageStorage} from "../../classes/Helper";
import Parser from "html-react-parser";

const { logo } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { contacts } = this.props,
            contentBlock = this.props.contentBlocks.find(val => val.name === 'under_header'),
            bg_image = typeof contentBlock !== "undefined" ? {backgroundImage: `url('${imageStorage + '/block_bg/' + contentBlock.bg_image}')`} : null,
            phone = typeof contacts[0] !== "undefined" ? contacts[0].phone : '';

        return (
            <div className="under-header" style={bg_image}>
                <div className="main-container">
                    <div className="ellipse-under-header"/>
                    <div className="name-site">
                        Dream Team Lab
                    </div>
                    <div className="logo">
                        <Picture
                            icon={logo}
                            height={'104px'}
                            alt={'dreamteamlab.ru'}
                        />
                    </div>
                    <div className="heading">
                        {typeof contentBlock !== "undefined" ? Parser(contentBlock.header) : null}
                    </div>
                    <div className="description">
                        {typeof contentBlock !== "undefined" ? Parser(contentBlock.description) : null}
                    </div>
                    <a className="phone-number" href={`tel:${phone.replaceAll(' ','')}`}>{phone}</a>
                </div>
                <div className="separate-bg">
                    <div className="left-part"/>
                    <div className="right-part"/>
                </div>
            </div>
        );
    }
}
