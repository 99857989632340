import React, { Component } from 'react';
import Slider from "react-slick";
import {NextArrow, PrevArrow} from "../../components/ArrowSlider";
import { imageStorage } from "../../classes/Helper";
import API from "../../classes/API";
import Parser from "html-react-parser";
import { Player, BigPlayButton } from 'video-react';


export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            gallery: props.gallery,
            mainFile: props.gallery[0],
            show: true,
        };
    }

    componentDidMount() {
        API.getGallery().then(data => {
            this.setState({
                gallery: data.data,
                mainFile: data.data[0],
            })
        })
    }

    playButton = () => {
        let test = document.querySelector('.image-block video');

        if(test !== null){
            if(test.paused){
                test.play();
            } else {
                test.pause();
            }
        }
    }

    render() {
        // const contentBlock = this.props.contentBlocks.find(val => val.name === 'about');

        const    { gallery, mainFile, show } = this.state;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow type='turquoise' />,
            prevArrow: <PrevArrow type='turquoise' />,
        };
        // const settings = {
        //         dots: false,
        //         infinite: true,
        //         speed: 500,
        //         slidesToShow: 3,
        //         slidesToScroll: 1,
        //         nextArrow: <NextArrow type='turquoise' />,
        //         prevArrow: <PrevArrow type='turquoise' />,
        //         responsive: [
        //             {
        //                 breakpoint: 767,
        //                 settings: {
        //                     slidesToShow: 1,
        //                     dots: true,
        //                 }
        //             },
        //             {
        //                 breakpoint: 1279,
        //                 settings: {
        //                     slidesToShow: 2,
        //                 }
        //             }
        //         ]
        //     };

        // const    settings = {
        //     dots: false,
        //     className: "gallery-slider",
        //     infinite: true,
        //     centerMode: true,
        //     centerPadding: 0,
        //     afterChange: index => {
        //         this.setState({
        //             mainFile: typeof gallery[index] !== 'undefined' ? gallery[index] : mainFile,
        //             show: true,
        //         })
        //     },
        //     beforeChange: index => {
        //         this.setState({
        //             mainFile: typeof gallery[index] !== 'undefined' ? gallery[index] : mainFile,
        //             show: false,
        //         })
        //     },
        //     speed: 300,
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     nextArrow: <NextArrow/>,
        //     prevArrow: <PrevArrow/>,
        //     responsive: [
        //         {
        //             breakpoint: 767,
        //             settings: {
        //                 slidesToShow: 1,
        //                 centerMode: false,
        //             }
        //         },
        //     ]
        // };

        return (
            <div className={'main-gallery main-container'} id='main-gallery'>
                <div className="heading">
                    Галерея
                </div>

                <div className="slider">
                    <Slider {...settings}>
                        {
                            gallery.filter(val => val.type !== 'video').map((val,index) => {
                                return <div className="image-block" key={index}>
                                    {
                                        val.type === 'video'
                                            ? null
                                            : <img src={imageStorage + '/gallery/' + val.name} alt={val.name} width={'100%'} height={'auto'}/>
                                    }
                                </div>
                            })
                        }
                    </Slider>
                </div>

                {/*<div className="list-image">*/}
                {/*    <Slider*/}
                {/*        {...settings}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            gallery.filter(val => val.type !== 'video').map((val,index) => {*/}
                {/*                return <div className="image-block" key={index}>*/}
                {/*                    {*/}
                {/*                        val.type === 'video'*/}
                {/*                            ? null*/}


                {/*                            // <Player*/}
                {/*                            //     width={'inherit'}*/}
                {/*                            //     height={'inherit'}*/}
                {/*                            //     playsInline*/}
                {/*                            //     src={imageStorage + '/gallery/' + mainFile.name}*/}
                {/*                            // >*/}
                {/*                            //     <BigPlayButton position="center" />*/}
                {/*                            // </Player>*/}
                {/*                            : <img src={imageStorage + '/gallery/' + val.name} alt={val.name} width={'256px'} height={'214px'}/>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Slider>*/}
                {/*</div>*/}
            </div>
        );
    }
}
