export default new class {
    constructor() {
        // this.server = 'http://localhost/~konstantinmarikan/dreamteamlabapi.loc/public/';
        // this.server = 'http://dreamteamapi/';
        this.server = 'https://api.dreamteamlab.ru/public/'
    }

    getNavigationData(){
        return this._callMethod(
            'navigation'
        )
    }

    getContentBlocks(){
        return this._callMethod(
            'content_blocks'
        )
    }

    getGallery(){
        return this._callMethod(
            'gallery'
        )
    }

    getDisciplines(){
        return this._callMethod(
            'disciplines',
        )
    }

    getCoaches(){
        return this._callMethod(
            'coaches',
        )
    }

    getRates(){
        return this._callMethod(
            'rates',
        )
    }

    getTimetableData(){
        return this._callMethod(
            'timetable',
        )
    }

    getNews(){
        return this._callMethod(
            'news',
        )
    }

    getContacts(){
        return this._callMethod(
            'contacts',
        )
    }

    getContent(){
        return this._callMethod(
            'get_content'
        )
    }

    postRequestForm(data){
        return this._callMethod(
            'post_request_form',
            data,
            'POST',
            'JSON'
        )
    }

    callFitness1C(postData){
        let params = {
            method: 'POST',
            mode: 'no-cors',
            body : {
                ...postData,
                'SurName': '',
                'LastName': '',
                'Comment': '',
                'ClubId': 'a001c5f0-f8c5-11e1-bd50-3860779088bc',
                'FormId': 'e21e117c-6343-11e9-898f-2c4d546554cd',
                'Source': 'http://fitness-centre.ru/',
                'Referrer': '?utm_source=google&utm_medium=cpc&utm_campaign=poisk_osnovnaya_rf&utm_content=694798648&utm_term=фитнес+зал',
                'utm_source': 'google',
                'utm_medium': 'cpc',
                'utm_campaign': 'poisk_osnovnaya_rf',
                'utm_content': '694798648',
                'utm_term': 'фитнес зал'
            },
            headers : {
                'Content-Type' : 'application/json',
                apikey : '147556',
                formid : '8973a1de-b493-2222-a7da-4a883bfc1111'
            }
        };

        return new Promise((resolve, reject) => {
            return fetch('https://polls.apiblueprint.org/hs/api/lead/', params)
            .then(response => {
                if(response.status !== 200) {
                    return reject(response);
                }

                return response.json();
            }).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }

    callTouchSendForm (postData) {
        const callTouchData = {
            ...postData,
            subject: 'Заявка с сайта',
            requestUrl: 'dreamteamlab.ru',
            sessionId: window.ct('calltracking_params','7bwtaiio').sessionId
        };

        const body = Object.keys(callTouchData).reduce((a,k) => {
            if(!!callTouchData[k]){
                a.push(k+'='+encodeURIComponent(callTouchData[k]));
            }
            return a
        },[]).join('&');

        let ct_site_id = '52549';
        let params = {
            method: 'POST',
            body : body,
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        };

        return new Promise((resolve, reject) => {
            return fetch('https://api.calltouch.ru/calls-service/RestAPI/requests/'+ct_site_id+'/register/', params)
            .then(response => {
                if(response.status !== 200) {
                    return reject(response);
                }

                return response.json();
            }).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }

    _callMethod(
        url,
        data = {},
        method = 'GET',
        type = '',
        headers = {},
        server = false,
    ) {
        let dataResult = [],
            postData = data;

        if(type === 'JSON'){
            postData = JSON.stringify(data);

            headers = {
                ...headers,
                'Content-Type' : 'application/json; charset=utf-8',
            }
        }

        for(let prop in data) {
            if(typeof data[prop] == 'object') {
                for(let subProp in data[prop]) {
                    dataResult.push(prop + '[' + subProp + ']=' + data[prop][subProp]);
                }
            } else {
                dataResult.push(prop + '=' + data[prop]);
            }
        }

        let params = {
            method: method,
            body : postData,
            headers : {
                ...headers,
            }
        };

        if(method !== 'POST') {
            delete params.body;
        }

        let serverUrl = this.server;

        if(server) {
            serverUrl = server;
        }

        return new Promise((resolve, reject) => {
            return fetch(serverUrl + url + (method === 'GET' ? '?' + dataResult.join('&') : ''), params)
                .then(response => {
                    if(response.status !== 200) {
                        return reject(response);
                    }

                    return response.json();
                }).then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        });
    }
}
