import React, { Component } from 'react';
import { ToastContainer } from 'react-toast';
import Header from './components/main-header';
import UnderHeader from './components/under-header';
import Disciplines from './components/disciplines';
import About from './components/about';
import Coaches from './components/coaches';
import RequestForm from './components/request-form';
import Rates from './components/rates';
import TimeTable from './components/timetable';
import News from './components/news';
import Gallery from './components/gallery';
import Contacts from './components/contacts';
import Footer from './components/main-footer';

import Picture from './components/Picture';
import images from './assets/images';
import API from "./classes/API";

import './assets/sass/styles.scss';
// import './assets/fonts'

const { scroll_top_button } = images;

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationData : [],
      contentBlocks : [],
      gallery : [],
      disciplines : [],
      coaches : [],
      rates : [],
      news : [],
      contacts : [{
        address: '',
        phone: '',
        working_hours: '',
        email: '',
        instagram: '',
        facebook: '',
      }],
      loadingContent: true,
    };
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    API.getContent().then(data => {
      const backData = data.data;

      this.setState({
        navigationData : backData.navigationData,
        contentBlocks : backData.contentBlocks,
        gallery: backData.gallery,
        disciplines: backData.disciplines,
        coaches: backData.coaches,
        rates: backData.rates,
        news : backData.news,
        contacts: backData.contacts,
        loadingContent: false,
      })

      // console.log(performance.now());
    });
  };

  render() {
    // console.log(performance.now());

    const {
      navigationData,
      contentBlocks,
      gallery,
      disciplines,
      coaches,
      rates,
      news,
      contacts,
      loadingContent
    } = this.state;

    return (
        <div className={'main-body-content ' + (loadingContent ? '' : 'show')}>
          <button type='button' id='scroll-top-button' onClick={this.scrollToTop}>
            <Picture icon={scroll_top_button} height='38px' alt={'scroll-top'}/>
            <span>Наверх</span>
          </button>
          <Header
              navigationData={navigationData}
              contacts={contacts}
          />
          <main>
            <UnderHeader
                contentBlocks={contentBlocks}
                contacts={contacts}
            />
            <About
                contentBlocks={contentBlocks}
                gallery={gallery}
            />
            <Disciplines
                contentBlocks={contentBlocks}
                disciplines={disciplines}
            />
            <Coaches
                coaches={coaches}
            />
            <RequestForm
                contentBlocks={contentBlocks}
            />
            <Rates
                rates={rates}
            />
            <TimeTable />
            <News
                news={news}
            />
            <Gallery gallery={gallery} />
            <Contacts
                contacts={contacts}
            />
          </main>
          <Footer
              contacts={contacts}
          />

          <ToastContainer delay={4000} />
        </div>
    );
  }
}
