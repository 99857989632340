import React, { Component } from 'react';
// import Slider from "react-slick";
// import {NextArrow, PrevArrow} from "../../components/ArrowSlider";
import { imageStorage } from "../../classes/Helper";
import API from "../../classes/API";
import Parser from "html-react-parser";
import { Player, BigPlayButton } from 'video-react';


export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            mainFile: props.gallery[0],
            show: true,
        };
    }

    componentDidMount() {
        API.getGallery().then(data => {
            this.setState({
                mainFile: data.data[0],
            })
        })
    }

    render() {
        const contentBlock = this.props.contentBlocks.find(val => val.name === 'about');
        const { mainFile, show } = this.state;

        return (
            <div className="about" id='about'>
                <div className="main-container">
                    <div className="about-content">
                        <div className="heading">
                            О клубе
                        </div>
                        <div className="description">
                            {typeof contentBlock !== "undefined" ? Parser(contentBlock.header) : null}
                        </div>

                        <div className="text">
                            {typeof contentBlock !== "undefined" ? Parser(contentBlock.description) : null}
                        </div>
                    </div>
                    <div className="gallery">
                        <div className={"active-image " + (show ? 'show' : '')}>
                            {typeof mainFile !== 'undefined' ? mainFile.type === 'video'
                                ? <Player
                                    width={'inherit'}
                                    height={'inherit'}
                                    playsInline
                                    src={imageStorage + '/gallery/' + mainFile.name}
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                                : <img src={imageStorage + '/gallery/' + mainFile.name} alt={mainFile.name}/> : null}
                        </div>
                    </div>

                    <div className="about-ellipse"/>
                </div>
            </div>
        );
    }
}
