import React, { Component } from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";

const { more_button_triangle } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const {heading,text,desc,setVisibleModal,visibleDescription,image} = this.props;

        return (
            <div className="discipline-block">
                <img src={image} alt={heading}/>
                <div className="description">
                    <div className="title">
                        {heading}
                    </div>
                    <div className="text">
                        {text}
                    </div>

                    <button className={"button-more-not-desktop" + (visibleDescription ? " hide" : "")} onClick={setVisibleModal(heading,desc,true)}>
                        <Picture icon={more_button_triangle} height={7} alt={'more button triangle'}/>
                        <span>подробнее</span>
                    </button>

                    <button className="button-more" onClick={setVisibleModal(heading,desc)}>
                        подробнее
                    </button>

                    <hr className={"discipline-line"  + (visibleDescription ? " show" : "")}/>
                </div>
            </div>
        );
    }
}
