import React, { Component } from 'react';
import {imageStorage} from "../../classes/Helper";

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
    }

    rotateBlock = () => {
        const {active} = this.state;

        this.setState({
            active: !active,
        })
    }

    render() {
        const {active} = this.state,
            { about, name, image, status } = this.props;

        return (
            <div className={"coach-block" + (active ? ' active' : '')}>
                <div className="rotate">
                    <div className="front">
                        <div className="coach-photo"  style={{
                            backgroundImage: `url(${imageStorage + '/coaches/' + image})`
                        }}/>
                        <div className="coach-info">
                            <div className="coach-name">{name}</div>
                            <div className="rank">{status}</div>
                            <button className="button-more" onClick={this.rotateBlock}>подробнее</button>
                        </div>
                    </div>
                    <div className="back">
                        <div className="coach-name">{name}</div>
                        <div className="coach-description">{about}</div>
                        <button className="button-return" onClick={this.rotateBlock}>назад</button>
                    </div>
                </div>
            </div>
        );
    }
}
