import React, { Component } from 'react';

import Picture from "../../components/Picture";
import images  from "../../assets/images";

const { cross } = images;


export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    setVisible = () => {
        const {visible} = this.state;

        this.setState({
            visible: !visible
        });
    }

    render() {
        const {visible} = this.state;

        return (
            <div className={"bg-shadow" + (visible ? ' show' : '')}>
                <div className="cross-out" onClick={this.setVisible}/>
                <div className={"modal-window" + (visible ? ' show' : '')}>
                    <div className="close-window" onClick={this.setVisible}>
                        <Picture icon={cross} height={'21px'} alt={'cross'}/>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
