import React, { Component } from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from '../../components/ArrowSlider';
import { imageStorage } from "../../classes/Helper";

import ModalWindow from "../../components/modal-window";
import DisciplineBlock from "../../components/disciplines/disciplineBlock";
import Helper from "../../classes/Helper";
import Picture from "../../components/Picture";
import images  from "../../assets/images";

const { more_button_triangle } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.modal = React.createRef();

        this.state = {
            heading: '',
            description: '',
            visibleDescription: false,
            disciplines: this.props.disciplines,
        };
    }

    setVisibleModal = (heading,description,visibleDescription) => () => {
        if(visibleDescription && Helper.isMobile()){
            this.setState({
                heading,
                description : description ? description : this.state.description,
                visibleDescription
            })
        } else if(!Helper.isMobile()){
            this.setState({
                heading: heading,
                description: description
            });

            this.modal.current.setVisible();
        } else {
            this.setState({
                visibleDescription: false,
            })
        }
    }

    _onClose = () => {
        return this.setVisibleModal('','',false);
    }

    render() {
        const contentBlock = this.props.contentBlocks.find(val => val.name === 'disciplines'),
            bg_image = typeof contentBlock !== "undefined" ? {backgroundImage: `url('${imageStorage + '/block_bg/' + contentBlock.bg_image}')`} : null,
            settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: <NextArrow/>,
                prevArrow: <PrevArrow/>,
                beforeChange: this._onClose,
            },
            { heading,description,visibleDescription } = this.state,
            { disciplines } = this.props;

        return (
            <div className="disciplines-container" id="disciplines" style={bg_image}>
                <div className="main-container">
                    <div className="heading">
                        дисциплины
                    </div>
                    <div className="disciplines">
                        {Helper.isMobile()
                            ? <Slider {...settings}>
                                {
                                    disciplines.map((val,index) => {
                                        return <DisciplineBlock
                                            key={index}
                                            visibleDescription={visibleDescription}
                                            setVisibleModal={this.setVisibleModal}
                                            image={imageStorage + '/disciplines/' + val.image}
                                            heading={val.name}
                                            text={val.short_desc}
                                            desc={val.desc}
                                        />
                                    })
                                }
                            </Slider>
                            : <>
                                {
                                    disciplines.map((val,index) => {
                                        return <DisciplineBlock
                                            key={index}
                                            visibleDescription={visibleDescription}
                                            setVisibleModal={this.setVisibleModal}
                                            image={imageStorage + '/disciplines/' + val.image}
                                            heading={val.name}
                                            text={val.short_desc}
                                            desc={val.desc}
                                        />
                                    })
                                }
                            </>}
                    </div>

                    <ModalWindow ref={this.modal}>
                        <div className="discipline-modal">
                            <div className="heading">{heading}</div>
                            <div className="description">{description}</div>
                        </div>
                    </ModalWindow>

                    <div className={'discipline-content-container' + (visibleDescription ? ' opened' : ' ')}>
                        <div className="discipline-content">
                            {description}
                            <button className="button-close-not-desktop" onClick={this.setVisibleModal(heading,description,false)}>
                                <Picture icon={more_button_triangle} height={7} alt={'more button triangle'}/>
                                <span>свернуть</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
