import React, {Component} from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";

const { more_button_triangle } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
    }

    visibleContent = (element) => {
        this.props.visibleContent(true,element,this.props.desc)
    }

    render() {
        const {image,date,title,visibleContentActive} = this.props;

        return (
            <div className="news-block">
                <div className="image-block" style={{
                    backgroundImage: `url(${image})`
                }}/>
                <div className="news-date">
                    {date}
                </div>
                <div className="news-title">
                    {title}
                </div>

                <button className={"button-more-not-desktop" + (visibleContentActive ? " hide" : "")} onClick={element => this.visibleContent(element)}>
                    <Picture icon={more_button_triangle} height={7} alt={'more button triangle'}/>
                    <span>подробнее</span>
                </button>

                <div className="news-bg">
                    <button className="button-more" onClick={element => this.visibleContent(element)}>
                        читать
                    </button>
                </div>
            </div>
        );
    }
}
