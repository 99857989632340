import logo from '../images/logo.png';
import logo2x from '../images/logo@2x.png';
import logo3x from '../images/logo@3x.png';

import logo_mobile from '../images/logo-mobile.png';
import logo_mobile2x from '../images/logo-mobile@2x.png';
import logo_mobile3x from '../images/logo-mobile@3x.png';

import footer_instagram from '../images/footer-instagram.png';
import footer_instagram2x from '../images/footer-instagram@2x.png';
import footer_instagram3x from '../images/footer-instagram@3x.png';

import footer_facebook from '../images/footer-facebook.png';
import footer_facebook2x from '../images/footer-facebook@2x.png';
import footer_facebook3x from '../images/footer-facebook@3x.png';

import contacts_instagram from '../images/contacts-instagram.png';
import contacts_instagram2x from '../images/contacts-instagram@2x.png';
import contacts_instagram3x from '../images/contacts-instagram@3x.png';

import contacts_facebook from '../images/contacts-facebook.png';
import contacts_facebook2x from '../images/contacts-facebook@2x.png';
import contacts_facebook3x from '../images/contacts-facebook@3x.png';

import arrow_slider_turquoise from '../images/arrow-slider-turquoise.png';
import arrow_slider_turquoise2x from '../images/arrow-slider-turquoise@2x.png';
import arrow_slider_turquoise3x from '../images/arrow-slider-turquoise@3x.png';

import arrow_slider_white from '../images/arrow-slider-white.png';
import arrow_slider_white2x from '../images/arrow-slider-white@2x.png';
import arrow_slider_white3x from '../images/arrow-slider-white@3x.png';

import scroll_top_button from '../images/scroll-top-button.png';
import scroll_top_button2x from '../images/scroll-top-button@2x.png';
import scroll_top_button3x from '../images/scroll-top-button@3x.png';

import cross from '../images/cross.png';
import cross2x from '../images/cross@2x.png';
import cross3x from '../images/cross@3x.png';

import more_button_triangle from '../images/more-button-triangle.png';
import more_button_triangle2x from '../images/more-button-triangle@2x.png';
import more_button_triangle3x from '../images/more-button-triangle@3x.png';

import check from '../images/check.png';
import check2x from '../images/check@2x.png';
import check3x from '../images/check@3x.png';



export default {
    logo: {
        'x1': logo,
        'x2': logo2x,
        'x3': logo3x
    },
    logo_mobile: {
        'x1': logo_mobile,
        'x2': logo_mobile2x,
        'x3': logo_mobile3x
    },
    footer_instagram: {
        'x1': footer_instagram,
        'x2': footer_instagram2x,
        'x3': footer_instagram3x
    },
    footer_facebook: {
        'x1': footer_facebook,
        'x2': footer_facebook2x,
        'x3': footer_facebook3x
    },
    contacts_instagram: {
        'x1': contacts_instagram,
        'x2': contacts_instagram2x,
        'x3': contacts_instagram3x
    },
    contacts_facebook: {
        'x1': contacts_facebook,
        'x2': contacts_facebook2x,
        'x3': contacts_facebook3x
    },
    arrow_slider_turquoise: {
        'x1': arrow_slider_turquoise,
        'x2': arrow_slider_turquoise2x,
        'x3': arrow_slider_turquoise3x,
    },
    arrow_slider_white: {
        'x1': arrow_slider_white,
        'x2': arrow_slider_white2x,
        'x3': arrow_slider_white3x,
    },
    scroll_top_button: {
        'x1': scroll_top_button,
        'x2': scroll_top_button2x,
        'x3': scroll_top_button3x,
    },
    cross: {
        'x1': cross,
        'x2': cross2x,
        'x3': cross3x,
    },
    more_button_triangle: {
        'x1': more_button_triangle,
        'x2': more_button_triangle2x,
        'x3': more_button_triangle3x,
    },
    check: {
        'x1' :check,
        'x2' :check2x,
        'x3' :check3x,
    },
};
