import React, { Component } from 'react';
import Picture from "../components/Picture";

import images  from "../assets/images";

const { arrow_slider_turquoise, arrow_slider_white } = images;


class NextArrow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { className, onClick, type } = this.props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <Picture height={'18px'} icon={type === 'turquoise' ? arrow_slider_turquoise : arrow_slider_white} alt={'arrow_slider_turquoise_left'}/>
            </div>
        );
    }
}

class PrevArrow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { className, onClick, type } = this.props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <Picture height={'18px'} icon={type === 'turquoise' ? arrow_slider_turquoise : arrow_slider_white} alt={'arrow_slider_turquoise_right'}/>
            </div>
        );
    }
}

export { NextArrow, PrevArrow };
