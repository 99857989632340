import React, {Component} from 'react';
import Slider from 'react-slick';
import {NextArrow, PrevArrow} from '../../components/ArrowSlider';
import CoachBlock from '../../components/coaches/coachBlock';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow type='turquoise' />,
      prevArrow: <PrevArrow type='turquoise' />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
          }
        },
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    },
        { coaches } = this.props;

    return (
      <div className='coaches' id='coaches'>
        <div className='main-container'>
          <div className='heading'>
            Команда
          </div>
          <div className='description'>
            наши тренеры
          </div>

          <div className='coaches-list'>
            <Slider {...settings}>
              {
                coaches.map((val,index) => {
                  return <CoachBlock key={index} {...val}/>
                })
              }
            </Slider>
          </div>

          <div className='coaches-ellipse' />
        </div>
      </div>
    );
  }
}
