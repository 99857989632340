import React, {Component} from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";
import Helper from "../../classes/Helper";
import Parser from 'html-react-parser';

const { contacts_facebook, contacts_instagram } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let contacts = this.props.contacts[0];

        if(typeof contacts !== "undefined"){
            contacts = this.props.contacts[0];
        }

        return (
            <div className="main-contacts" id="contacts">
                <div className="main-container">
                    <div className="content">
                        <div className="heading">
                            Контакты
                        </div>
                        <div className="content-block">
                            <div className="heading-content-block">
                                адрес
                            </div>
                            <div className="text-content-block">
                                {contacts.address}
                            </div>
                        </div>
                        <div className="content-block">
                            <div className="heading-content-block">
                                Телефон
                            </div>
                            <div className="text-content-block">
                                <a href={`tel:${contacts.phone.replaceAll(' ','')}`}>
                                    {contacts.phone}
                                </a>
                            </div>
                        </div>
                        <div className="content-block">
                            <div className="heading-content-block">
                                часы работы
                            </div>
                            <div className="text-content-block">
                                {Parser(contacts.working_hours)}
                            </div>
                        </div>

                        <div className="contacts-links">
                            <a className="contact-email" href={`mailto:${contacts.email}`}>{contacts.email}</a>

                            <div className="social-links">
                                <a href={contacts.instagram} target="_blank" rel="nofollow noopener">
                                    <Picture
                                        icon={contacts_instagram}
                                        height={Helper.isDesktop() ? "38px" : "32px"}
                                        alt={'instagram'}
                                    />
                                </a>
                                <a href={contacts.facebook} target="_blank" rel="nofollow noopener">
                                    <Picture
                                        icon={contacts_facebook}
                                        height={Helper.isDesktop() ? "38px" : "32px"}
                                        alt={'facebook'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    {!Helper.isMobile() && <div className="map">
                        <iframe
                            title={'yandex maps'}
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A3c9587424f5dddceb105c0c7ffc94fe12bedd1270868d6986398d0628f11feea&amp;source=constructor"
                            width={Helper.isDesktop() ? '534' : '360'} height={Helper.isDesktop() ? "384" : '315'} frameBorder="0"/>
                    </div>}
                    <div className="contact-ellipse"/>
                </div>
                {Helper.isMobile() && <div className="map">
                    <iframe
                        title={'yandex maps'}
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A3c9587424f5dddceb105c0c7ffc94fe12bedd1270868d6986398d0628f11feea&amp;source=constructor"
                        width="100%" height="384" frameBorder="0"/>
                </div>}
            </div>
        );
    }
}
