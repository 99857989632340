import React, { Component } from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";

const { footer_facebook, footer_instagram } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let contacts = this.props.contacts[0];

        if(typeof contacts !== "undefined"){
            contacts = this.props.contacts[0];
        }

        return (
            <footer className="main-footer">
                <div className="main-container">
                    <div className="copyright">
                        <div>
                            © 2020  Dream Team Lab. Все права защищены
                        </div>
                        <div className="link-on-developer">
                            <a href="https://teleg.run/azshar" target="_blank" rel="nofollow noopener">
                                Разработка сайта: <span>AZ-web</span>
                            </a>
                        </div>
                    </div>
                    <div className="contacts">
                        <div className="phone-number">
                            <a href={`tel:${contacts.phone.replaceAll(' ','')}`}>{contacts.phone}</a>
                        </div>
                        <div className="social-links">
                            <a href={contacts.instagram} target="_blank" rel="nofollow noopener">
                                <Picture
                                    icon={footer_instagram}
                                    height={"16px"}
                                    alt={'instagram'}
                                />
                            </a>
                            <a href={contacts.facebook} target="_blank" rel="nofollow noopener">
                                <Picture
                                    icon={footer_facebook}
                                    height={"16px"}
                                    alt={'facebook'}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container footer-links-container">
                    <hr/>
                    <div className="footer-links">
                        <a target="_blank" href="https://api.dreamteamlab.ru/public/documents/visiting_rules.pdf">Правила посещения</a>
                        <a target="_blank" href="https://api.dreamteamlab.ru/public/documents/user_agreement.pdf">Пользовательское соглашение</a>
                        <a target="_blank" href="https://api.dreamteamlab.ru/public/documents/public_offer.pdf">Публичная оферта</a>
                        <a target="_blank" href="https://api.dreamteamlab.ru/public/documents/privacy_policy.pdf">Политика конфиденциальности</a>
                    </div>
                </div>
            </footer>
        );
    }
}
