export default new class  {

    isDesktop(){
        return document.documentElement.clientWidth >= 1280;
    }
    isMobile(){
        return document.documentElement.clientWidth <= 767;
    }
}

// export const imageStorage = 'http://localhost/~konstantinmarikan/dreamteamlabapi.loc/public/images';
export const imageStorage = 'https://api.dreamteamlab.ru/public/images';
