import React, { Component } from 'react';
import Picture from "../../components/Picture";
import images  from "../../assets/images";
import Helper from "../../classes/Helper";

const { logo, logo_mobile, contacts_facebook, contacts_instagram } = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.state = {
            openedMenu: false,
        };
    }

    setMenu = () => {
        this.setState({
            openedMenu: !this.state.openedMenu,
        })
    }

    scrollTo = event => {
        event.preventDefault();

        const anchor = event.target,
            blockID = anchor.getAttribute('href').substr(1);

        if(Helper.isMobile()){
            this.setState({
                openedMenu: false,
            })
        }

        document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    render() {
        const {openedMenu} = this.state,
            { navigationData, contacts } = this.props;

        let linkInstagram = 'https://www.instagram.com/',
            linkFacebook = 'https://www.facebook.com/';

        if(typeof contacts[0] !== "undefined"){
            linkInstagram = contacts[0].instagram;
            linkFacebook = contacts[0].facebook;
        }

        return (
            <>
                <header className="main-header">
                    <nav className="main-nav main-container">
                        <div className="logo">
                            <Picture
                                icon={logo}
                                height={'68px'}
                                alt={'dreamteamlab.ru'}
                            />
                        </div>

                        <ul>
                            {
                                navigationData.map((val,index) => {
                                    return <li key={val.id}>
                                        <a className={index === 0 ? 'active' : null} href={`#${val.block}`} onClick={this.scrollTo}>{val.name}</a>
                                    </li>
                                })
                            }
                        </ul>
                    </nav>
                </header>

                <header className="main-header-mobile" id='main-header-mobile'>
                    <div className="logo">
                        <Picture
                            icon={logo_mobile}
                            height={'40px'}
                            alt={'dreamteamlab.ru'}
                        />
                    </div>

                    <div className={'set-menu' + (openedMenu ? ' opened' : '')} onClick={this.setMenu}/>

                    <div className={'menu' + (openedMenu ? ' opened' : '')}>
                        <nav className="main-nav main-container">
                            <ul>
                                {navigationData.map((val,index) => {
                                    return <li key={val.id}>
                                        <a className={index === 0 ? 'active' : ''}
                                            href={`#${val.block}`}
                                            onClick={this.scrollTo}>
                                            {val.name}
                                        </a>
                                    </li>
                                })}
                            </ul>
                            <div className="social-contacts">
                                <a href={linkInstagram} target="_blank" rel="nofollow noopener">
                                    <Picture
                                        icon={contacts_instagram}
                                        height={'38px'}
                                        alt={'instagram'}
                                    />
                                </a>
                                <a href={linkFacebook} target="_blank" rel="nofollow noopener">
                                    <Picture
                                        icon={contacts_facebook}
                                        height={'38px'}
                                        alt={'facebook'}
                                    />
                                </a>
                            </div>
                        </nav>
                    </div>
                </header>
            </>
        );
    }
}
