import React, { Component } from 'react';
import { toast } from 'react-toast'
import InputMask from "react-input-mask";
import API from "../../classes/API";
import Parser from "html-react-parser";
import {imageStorage} from "../../classes/Helper";
import ModalWindow from "../modal-window";
import images from "../../assets/images";
import Picture from "../Picture";

const {check} = images;

export default class extends Component{
    constructor(props) {
        super(props);

        this.modal = React.createRef();

        this.state = {
            upload: false,
        };
    }

    _changeField = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    setVisibleModal = () => {
        this.modal.current.setVisible();
    }

    postRequestForm = event => {
        const {name, email, phone} = this.state;

        event.preventDefault();

        this.setState({
            upload: true,
        });

        return Promise.all([
            API.postRequestForm(this.state),
            API.callTouchSendForm({
                fio: name,
                phoneNumber: phone,
                email: email,
            })
            // API.callFitness1C({
            //     'Name': this.state.name,
            //     'Phone': this.state.phone,
            //     'Email': this.state.email,
            //     'DateTime': new Date().toISOString(),
            // }),
        ]).then(() => {
            return this.setState({
                upload: false,
            }, () => {
                this.modal.current.setVisible();

                setTimeout(() => {
                    this.modal.current.setVisible();
                }, 5000);
                // toast('Ваша заявка успешно отправлена!', {
                //     backgroundColor: '#7cc8c9',
                // });
            })
        }).catch(() => {
            return this.setState({
                upload: false,
            })
        })
    }

    render() {
        const contentBlock = this.props.contentBlocks.find(val => val.name === 'form_request');
        const {upload} = this.state;
        const bg_image = typeof contentBlock !== "undefined" ? {backgroundImage: `url('${imageStorage + '/block_bg/' + contentBlock.bg_image}')`} : null;

        return (
            <div className="request-form" style={bg_image}>
                <div className="main-container">
                    <div className="description-container">
                        <div className="heading">
                            Заявка
                        </div>
                        <div className="description">
                            {typeof contentBlock !== "undefined" ? Parser(contentBlock.description) : null}
                        </div>
                    </div>

                    <form onSubmit={this.postRequestForm}>
                        <label htmlFor={'name'}>Имя</label>
                        <input
                            id={'name'}
                            className="form-input"
                            name="name"
                            type="text"
                            placeholder="Имя"
                            pattern="^[А-Яа-яЁё\s]+$"
                            required
                            onChange={this._changeField}
                        />
                        <label htmlFor={'phone'}>Телефон</label>
                        <InputMask mask="+7(999)999-99-99" onChange={this._changeField}>
                            <input
                                id={'phone'}
                                className="form-input"
                                name="phone"
                                type="text"
                                placeholder="Телефон"
                                required
                            />
                        </InputMask>
                        <label htmlFor={'email'}>E-mail</label>
                        <input
                            id={'email'}
                            className="form-input"
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            required
                            onChange={this._changeField}
                        />
                        <div className="checkbox-block">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="assent"
                                onChange={this._changeField}
                                required
                            />
                            <label htmlFor="checkbox" className="checkbox-class">
                                <span className="checkbox">
                                    <span className="checkbox-checked"/>
                                </span>
                                Я согласен на обработку моих персональных данных
                            </label>
                        </div>
                        <button type="submit" disabled={upload}>
                            <span className={'submit-update' + (upload ? ' show' : '')}/>
                            <span className={'submit-text' + (upload ? ' hide' : '')}>Записаться</span>
                        </button>
                    </form>

                    <ModalWindow ref={this.modal}>
                        <div className="request-form-modal">
                            <div className="check-container">
                                <Picture
                                    icon={check}
                                    height={"50px"}
                                    width={"50px"}
                                    alt={'Заявка успешно отправлена'}
                                />
                            </div>
                            <div className="description">Спасибо!<br/>Ваша заявка отправлена.<br/>В ближайшее время мы с Вами свяжемся!</div>
                            <button className="close-modal" onClick={this.setVisibleModal}>Ок</button>
                        </div>
                    </ModalWindow>
                </div>
            </div>
        );
    }
}
