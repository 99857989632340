import React, { Component } from 'react';

export default class extends Component{
    constructor(props) {
        super(props);
    }
    render() {
        const {icon, height, className, alt ,onClick} = this.props;

        return (
            typeof icon !== 'undefined' &&  <picture onClick={onClick}>
                <source srcSet={icon.x3} media='(-webkit-min-device-pixel-ratio: 2),(min-resolution: 120dpi)' />
                <source srcSet={icon.x2} media='(max-width: 768px)' />
                <img
                    src={icon.x1}
                    className={className}
                    height={height}
                    alt={alt}
                />
            </picture>
        );
    }

}
