import React, { Component } from 'react';
import {NextArrow, PrevArrow} from "../ArrowSlider";
import Slider from "react-slick";
import API from "../../classes/API";

export default class extends Component{
    constructor(props) {
        super(props);

        this.days = [
            {
                key : 0,
                name : 'Пн'
            },
            {
                key : 1,
                name : 'Вт'
            },
            {
                key : 2,
                name : 'Ср'
            },
            {
                key : 3,
                name : 'Чт'
            },
            {
                key : 4,
                name : 'Пт'
            },
            {
                key : 5,
                name : 'Сб'
            },
            {
                key : 6,
                name : 'Вс'
            },
        ];

        this.state = {
            timetable: [],
        };
    }

    componentDidMount() {
        API.getTimetableData().then(data => {
            this.setState({
                timetable: data.data,
            })
        })
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 0,
            arrows:false,
            nextArrow: <NextArrow type='turquoise'/>,
            prevArrow: <PrevArrow type='turquoise'/>,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                    }},
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: true,
                    }},
            ]
        },
            { timetable } = this.state;

        return (
            <div className="timetable-container main-container" id="timetable">
                <div className="heading">
                    Расписание
                </div>
                <div className="description">
                    групповые занятия
                </div>

                <div className="timetable">
                    <div className="content-timetable">
                        <Slider {...settings}>
                            {
                                timetable.map((val,index) => {
                                    return <div className="col-timetable" key={index}>
                                        <div className="header-timetable">
                                            {this.days.find(item => item.key === index).name}
                                        </div>
                                        {
                                            val.map((item,itemIndex) => {
                                                return <div className="cell-timetable" key={itemIndex}>
                                                    <div className="time-start">
                                                        {
                                                            item.time_start.split(':').slice(0,2).join(':')
                                                        }
                                                    </div>
                                                    <div className="name-workout">
                                                        {item.name}
                                                    </div>
                                                    <div className="duration-workout">
                                                        {item.duration} мин
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}
