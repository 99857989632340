import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener('scroll', () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop,
        scrollTopButton = document.getElementById('scroll-top-button');

    if(scrollTop > 180){
        scrollTopButton.classList.add('show');
    } else {
        scrollTopButton.classList.remove('show');
    }
});

window.addEventListener('keydown', (event) => {
    if(event.key === 'Escape'){
        let bgShadow = document.querySelector('.bg-shadow');

        if(bgShadow.classList.contains('show')){
            bgShadow.classList.remove('show');
            bgShadow.querySelector('.modal-window').classList.remove('show');
        }
    }
})

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
